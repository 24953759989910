<template>
  <component :is="$route.meta.layout">
    <v-card v-if="pristine">
      <v-skeleton-loader
        data-cy="skeleton-loader"
        :loading="pristine"
        type="table"
      />
    </v-card>

    <v-card
      v-else
      data-cy="rooms-data-card"
    >
      <v-card-title>
        Scopes related to this account
        <v-spacer />
        <v-text-field
          v-model="customParams.name"
          data-cy="rooms-filter"
          append-icon="mdi-magnify"
          name="search"
          label="Search"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        data-cy="rooms-data-table"
        :headers="headers"
        :items="scopes"
        :server-items-length="total"
        :footer-props="{
          itemsPerPageOptions: [15, 30, 50, -1],
        }"
        :items-per-page="pagination.take"
        @pagination="updatePagination"
        @update:items-per-page="updateTake"
        @update:options="updateSort"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-spacer />
            <v-btn
              color="primary"
              small
              class="mb-2"
              @click="navigateToCreate"
            >
              Create new Scope
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:item="{ item }">
          <tr
            data-cy="rooms-data-row"
            class="tw-cursor-pointer"
            @click="navigateToUpdate(item.scopeId)"
          >
            <td>{{ item.name }}</td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2 hover:tw-text-yellow-500"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="navigateToUpdate(item.scopeId)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit Scope</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="hover:tw-text-red-500"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="selectItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete Scope</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>

    <ConfirmDialog :active="dialog">
      <template v-slot:title>
        Are you sure?
      </template>

      <template v-slot:text>
        <p>
          This action will delete the scope
          <span
            class="tw-font-bold"
            v-text="`'${selected.name}' and everything in it!`"
          />.
        </p>
        <p class="tw-font-bold">
          This includes Rooms, Hosts and Participations.
        </p>
        <p class="tw-text-red-500 tw-font-bold">
          This action cannot be undone.
        </p>
      </template>

      <template v-slot:cancel>
        <v-btn
          small
          color="primary"
          @click="selectItem()"
        >
          Cancel
        </v-btn>
      </template>
      <template v-slot:confirm>
        <v-btn
          small
          color="error"
          @click="removeScope(selected.scopeId)"
        >
          Confirm
        </v-btn>
      </template>
    </ConfirmDialog>
  </component>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { debounce } from 'lodash';
import ConfirmDialog from '../../components/ConfirmDialog';
import pagination from '../../helpers/pagination';
import confirms from '../../helpers/confirms';

export default {
  components: {
    ConfirmDialog,
  },

  mixins: [pagination, confirms],

  data: () => ({
    headers: [
      { text: 'Scope name', sortable: true, value: 'name' },
      { text: 'Actions', sortable: false, value: 'actions' },
    ],
  }),

  computed: {
    ...mapState('ScopesModule', ['pristine', 'scopes', 'total']),
  },

  watch: {
    computedParams: {
      deep: true,
      immediate: true,
      // eslint-disable-next-line func-names
      handler: debounce(function () {
        this.listScopes(this.computedParams)
          .catch(() => this.setAlert({
            message: 'There was an error fetching the Scopes. Please try again later.',
          }));
      }, 250),
    },
  },

  methods: {
    ...mapMutations('AlertModule', ['setAlert']),
    ...mapActions('ScopesModule', ['listScopes', 'deleteScope']),

    navigateToCreate() {
      this.$router.push({ name: 'ScopeCreate' });
    },

    navigateToUpdate(scopeId) {
      this.$router.push({ name: 'ScopeUpdate', params: { scopeId } });
    },

    async removeScope(scopeId) {
      try {
        this.selectItem();
        await this.deleteScope(scopeId);
        await this.listScopes();
      } catch (error) {
        this.setAlert({
          message: 'There was an error deleting the Account. Please try again later.',
        });
      }
    },
  },
};
</script>
