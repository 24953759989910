<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    class="tw-p-4"
  >
    <v-row>
      <v-col>
        <v-text-field
          v-model="formData.name"
          :counter="120"
          :rules="formValidation.name"
          label="Name for the new Scope."
          name="scope_name"
          persistent-hint
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="validate"
        >
          <slot name="confirmButtonText" />
        </v-btn>

        <v-btn
          color="error"
          class="mr-4"
          @click="$emit('form-cancel')"
        >
          <slot name="cancelButtonText" />
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { isEmpty, cloneDeep } from 'lodash-es';

export default {
  props: {
    scopeData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  data: () => ({
    valid: true,
    formData: {
      name: '',
    },
    formValidation: {
      name: [
        (v) => (v.length <= 120 || 'Name must be less than 120 characters long.'),
      ],
    },
  }),

  watch: {
    scopeData: {
      deep: true,
      handler(newVal) {
        if (!isEmpty(newVal)) this.formData = cloneDeep(newVal);
      },
    },
  },

  created() {
    if (!isEmpty(this.scopeData)) this.formData = cloneDeep(this.scopeData);
  },

  methods: {
    validate() {
      if (this.$refs.form.validate()) this.$emit('form-submit', { ...this.formData });
    },
  },
};
</script>
