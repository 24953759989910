<template>
  <component :is="$route.meta.layout">
    <v-card>
      <v-card-title primary-title>
        {{ `Update: "${ formData.name || 'a scope' }"` }}
      </v-card-title>

      <ScopeForm
        :scope-data="formData"
        @form-cancel="cancelCreation"
        @form-submit="submitScope"
      >
        <template v-slot:cancelButtonText>
          Cancel
        </template>

        <template v-slot:confirmButtonText>
          Update scope
        </template>
      </ScopeForm>
    </v-card>
  </component>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { cloneDeep } from 'lodash';
import ScopeForm from '../../components/forms/ScopeForm';

export default {
  components: {
    ScopeForm,
  },

  props: {
    scopeId: {
      type: String,
      required: true,
      default: '',
    },
  },

  data: () => ({
    formData: {},
  }),

  async created() {
    const scopeData = await this.fetchScope(this.scopeId);
    this.formData = cloneDeep(scopeData);
  },

  methods: {
    ...mapMutations('AlertModule', ['setAlert']),
    ...mapActions('ScopesModule', ['fetchScope', 'updateScope']),

    cancelCreation() {
      this.$router.push({ name: 'ScopeIndex' });
    },

    async submitScope(scopeData) {
      try {
        const { name } = scopeData;
        await this.updateScope({ name, scopeId: this.scopeId });
        this.$router.push({ name: 'ScopeIndex' });
      } catch (error) {
        this.setAlert({
          message: `There was an error updating the scope <strong>"${this.formData.name}"</strong>. Please try again later.`,
        });
      }
    },
  },
};
</script>
