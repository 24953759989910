<template>
  <component :is="$route.meta.layout">
    <v-card>
      <v-card-title primary-title>
        Create a new scope
      </v-card-title>

      <ScopeForm
        @form-cancel="cancelCreation"
        @form-submit="submitScope"
      >
        <template v-slot:cancelButtonText>
          Cancel
        </template>

        <template v-slot:confirmButtonText>
          Add scope
        </template>
      </ScopeForm>
    </v-card>
  </component>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import ScopeForm from '../../components/forms/ScopeForm';

export default {
  components: {
    ScopeForm,
  },

  methods: {
    ...mapMutations('AlertModule', ['setAlert']),
    ...mapActions('ScopesModule', ['createScope']),

    cancelCreation() {
      this.$router.push({ name: 'ScopeIndex' });
    },

    async submitScope(scopeData) {
      try {
        await this.createScope(scopeData);
        this.$router.push({ name: 'ScopeIndex' });
      } catch (error) {
        this.setAlert({
          message: `There was an error creating the scope <strong>"${scopeData.name}"</strong>. Please try again later.`,
        });
      }
    },
  },
};
</script>
